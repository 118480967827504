import { defineStore } from 'pinia'

export const useVehiculosVnStore = defineStore('vehiculos-vn-store', () => {
  const initFilters = ref(undefined);
  
  const totalResults = ref(0);

  const filters = ref({
    marcas:[],
    modelos:[],
    version:'',
    precio_max:'',
    provincias:[],
    orden:'ASC',
    anio_min:'',
    anio_max:'',
    combustibles:[],
    distintivos:[],
    cilindrada_min:'',
    cilindrada_max:'',
    potencia_min:'',
    potencia_max:'',
    query:'',
    queryLabel:'',
    transmisiones:[],
  }); 

  const cleanFilters = () => {
    
    const keys = Object.keys(filters.value);
    keys.forEach(key => {
      const item = filters.value[key];
      if(key != 'orden'){
        if(typeof item == 'object'){
          filters.value[key] = [];
        }
        else{
          filters.value[key] = '';
        }
      }
    });

  };


  const setFilters = (newFilters: Object) => {
    
    const keys = Object.keys(filters.value);
    keys.forEach(key => {
      const item = filters.value[key];
      if(key != 'orden'){
        if(typeof item == 'object'){
          filters.value[key] = newFilters[key] === '' ? []: newFilters[key].split(',');
        }
        else{
          filters.value[key] = newFilters[key];
        }
      }
    });

  };

  const cmpTotalResults = computed(() => {

    return totalResults.value == 1 ? `Ver ${totalResults.value} coche` : `Ver los ${totalResults.value} coches` ;

  });

  const numTotalFilters = computed(() => {


    let total = 0;

    const keys = Object.keys(filters.value);
    keys.forEach(key => {
      
      const item = filters.value[key];


      if(key != 'orden' && key != 'queryLabel'){
        if(typeof item == 'object'){
          total+= filters.value[key].length;
        }
        else{
          total = filters.value[key].toString() === '' ? total : total+1;
        }
      }

    });

    return total;

  });
/**
 * 
 * Formatea todos los filtros de la estore devolviendo un formdata
 * @return {FormData} formData 
 */

  const formDataFilters = computed(() => {

    const formData = new FormData();

    const keys = Object.keys(filters.value);
    keys.forEach(key => {
      
      const item = filters.value[key];
      formData.append(key,filters.value[key].toString());

    });

    return formData;

  });

  
  
  return { initFilters, filters, cmpTotalResults, totalResults, cleanFilters, setFilters, numTotalFilters, formDataFilters};
  
});
  